import React from 'react';
import { Button, List } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBox, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import '../css/Sidebar.css';import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import InventoryIcon from '@mui/icons-material/Inventory';import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaymentIcon from '@mui/icons-material/Payment';
import CategoryIcon from '@mui/icons-material/Category';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
const Sidebar = () => {
  return (
    <div className="sidebar">
      
      {/* <h1 className='h1'>Agro Vet Care</h1> */}
      <List>
        <ul className="menu">
          <li className="menu-item">
            <Link to="/" className="menu-link">
              <FontAwesomeIcon icon={faHome} />&nbsp;
              Dashboard
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/category-list" className="menu-link">
            <CategoryIcon icon={CategoryIcon} />&nbsp;
              Category
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/product-management" className="menu-link">
              <InventoryIcon icon={InventoryIcon} />&nbsp;
              Product
            </Link>
          </li>
          
          <li className="menu-item">
            <Link to="/advertisement" className="menu-link">
            <AddToPhotosIcon icon={AddToPhotosIcon} />&nbsp;
              Advertisement
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/customer-list" className="menu-link">
              <FontAwesomeIcon icon={faUsers} />&nbsp;
              Customer Verify
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/orders" className="menu-link">
            <ShoppingCartIcon icon={ShoppingCartIcon} />&nbsp;

              Orders
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/payments" className="menu-link">
              <PaymentIcon icon={PaymentIcon} />&nbsp;
              Payments
            </Link>
          </li>
          <li className="menu-item">
                <Link to="/blogs" className="menu-link">
                <ViewAgendaIcon icon={ViewAgendaIcon} />&nbsp;

                  Blogs
                </Link>
              </li>
              <li className="menu-item">
            <Link to="/contact-us" className="menu-link">
            <AddIcCallIcon icon={AddIcCallIcon} />&nbsp;
              Contact Us
            </Link>
          </li>
        </ul>
      </List>
    </div>
  );
};

export default Sidebar;
